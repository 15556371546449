/* Container for the cards */
.card-container-logistics {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    align-items: center;
}

.logistics-map-divide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}

/* Card styling */
.card-logistics {

    color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: left;
    margin-top: 10px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.card-logistics div {
    margin: 0.5rem 0;
}

.card-logistics:hover {
    transform: scale(1.05);
}

/* Card title */
.card-logistics-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Card info text */
.card-logistics-info {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .card-logistics {
        width: 100%;
        margin-top: 10px;
    }

    .logistics-map-divide {
        flex-direction: column;
    }
}

/* Overlay background */
.card-logistics-overlay {
    position: fixed;
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* Adds spacing to prevent content from touching edges */
    z-index: 1000;
}

/* Detail card styling with scroll */
.card-logistics-detail-card {
    background-color: white;
    color: #333;
    padding: 10px;
    border-radius: 10px;
    max-width: 75%;
    max-height: 100%;
    /* Restrict height to 80% of viewport */
    overflow-y: auto;
    /* Enable scrolling if content overflows */
    text-align: start;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
}

.card-logistics-detail-card div {
    margin: 1rem 0;
}

/* Close button styling */
.card-logistics-close-btn {
    background-color: #00796b;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.card-logistics-close-btn:hover {
    background-color: #005f56;
}

.container-value {
    align-items: baseline;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    flex-wrap: wrap;
    align-content: space-around;
}

.card-container-value {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
    /* Ensures padding and border are included in width calculation */
    overflow: hidden;
    /* Prevents overflow that may cause scrolling */
}

/* For tablets and smaller devices (width ≤ 768px) */
@media (max-width: 768px) {
    .card-container-value {
        padding: 15px;
        margin: 10px;
        width: calc(100% - 20px);
        margin-top: 2rem;
        /* Ensures padding is accounted for */
    }
}

/* For mobile devices (width ≤ 480px) */
@media (max-width: 480px) {
    .card-container-value {
        height: 13rem;
        padding: 10px;
        margin: 5px;
        width: calc(100% - 50px);
        margin-top: 2rem;
        /* Ensures padding is accounted for */
    }

    .card-container-value button {
        margin: 1rem auto;
    }
}




.no-scroll-view {
    overflow: hidden;
}

.LogisticsMainDiv .searchFilterDiv .serachOption {
    margin: 10px auto;
}

.LogisticsSectionMainDiv .searchFilterDiv .serachOption {
    text-align: right;
    display: inline-block;
    width: 150px;
}

.LogisticsSectionMainDiv .searchFilterDiv .serachOption button:disabled {
    background-color: #dedede;
    color: #999;
}

.LogisticsSectionMainDiv .searchFilterDiv .serachOption button {
    border-radius: 5px;
    box-shadow: 0 0 25.4px 1.6px rgb(0 0 0 / 9%);
    background-color: #1c9ae2;
    border: 0;
    color: #fff;
    padding: 7px 10px;
    width: 90%;
    display: inline-block;
    position: relative;
    top: 0px;
}

.LogisticsSectionMainDiv .searchFilterDiv input {
    width: 350px;
    padding: 3px 10px;
    line-height: 30px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    background-color: #f9f9f9;
}

/* For tablets and smaller devices (width ≤ 768px) */
@media (max-width: 768px) {
    .LogisticsSectionMainDiv .searchFilterDiv input {
        width: 250px;
        padding: 5px 12px;
        line-height: 28px;
    }
}

/* For mobile devices (width ≤ 480px) */
@media (max-width: 480px) {
    .LogisticsSectionMainDiv .searchFilterDiv input {
        width: 100%;
        padding: 8px 15px;
        line-height: 25px;
    }
}


.mapPopup {
    width: 100%;
    height: 100%;
    padding: 30px 0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.42);
    position: relative;
    visibility: visible;
}

.mapPopup h4 {
    display: block;
    margin: 0 auto;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #076d8e;
}

.mapPopup p {
    display: block;
    margin: 10px auto;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: start;
}

.mapPopup .closeBtnPopup {
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    margin: 10px;
    background: #ff4d4d;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0px;
    font-size: 18px;
    text-align: center;
    top: 5px;
    line-height: 30px;
    border: 0;
}

.mapPopup .closeBtnPopup:hover {
    color: #fff !important;
}

.scrollable-content {
    flex-grow: 1;
    padding-left: 1rem;
    overflow-y: auto;
    /* Allows vertical scrolling */
    margin-right: 10px;
    margin-bottom: 20px;
}

.scrollable-content-info {
    margin-top: -20px;
    max-height: 50%;
    margin-right: 10px;
    padding-left: 1rem;
    overflow-y: auto;
}

.scrollable-content-info div {
    margin: 1rem 0;
}

.mapPopup-close {
    position: absolute;
    padding: 30px;
    left: auto;
    right: 10px;
    top: -15px;
    border-radius: 8px;
}

.title-card-view {
    max-width: 80%;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.closeBtnPopup-close {
    position: absolute;
    top: 10px;
    /* Adjust top positioning */
    right: 10px;
    /* Adjust right positioning */
    background-color: #ff4d4d;
    /* Red background for the close button */
    color: white;
    /* White text */
    border: none;
    /* Remove the default border */
    padding: 8px 15px;
    /* Add padding to make it more clickable */
    font-size: 16px;
    /* Font size for the X */
    border-radius: 100%;
    /* Circular button */
    cursor: pointer;
    /* Add a pointer cursor on hover */
    transition: background-color 0.3s ease;
    /* Smooth transition for hover effect */
}

.closeBtnPopup-close :hover {
    background-color: #ff1a1a;
    /* Darker red when hovered */
}

.card-container-logistics-internal {
    background-color: #307E83;
    padding: 20px;
    border-radius: 8px;
    color: white;
    background-size: cover;
    background-position: center;
    min-height: 3rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.pagination-controls {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-evenly;
}

.pagination-controls button {
    padding: 8px 16px;
    margin: 0 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination-controls button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination-controls span {
    font-size: 14px;
    color: #333;
}

.portMessageSignsMain-view .labelTxt {
    display: inline-block;
    width: 100%;
    margin-top: -15px;
}

.portMessageSignsMain-view .locationTxt {
    width: 90%;
    font-weight: bold;
    padding: 5px;
}

.portMessageSignsMain-view .dateTimeTxt {
    font-size: 16px;

}

.portMessageSignsMain-view .msgDataDiv {
    background: #000;
    color: #fb4a4a;
    font-size: 20px;
    line-height: 22px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin: 10px auto;
    font-weight: 500;
    letter-spacing: 1px;
}

.nextline-view {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    font-size: 16px;
}

.nextline-view a {
    word-break: break-word;
    overflow-wrap: break-word;
}

.responsive-map {
    width: 59%
}

.responsive-list {
    width: 39%;
}

@media (max-width: 768px) {

    .responsive-map,
    .responsive-list {
        width: 100%;
    }

    .responsive-map {
        order: 1;
        /* Ensure Map appears first */
    }

    .responsive-list {
        order: 2;
    }
}

.card-same-rows {
    display: flex;
    gap: 10px;
    flex-direction: row;
}


@media (max-width: 920px) {
    .gm-style .gm-style-iw-c {
        min-width: 280px !important;
        width: auto !important;
    }

    .mapPopup {
        max-height: 30rem;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {
    .gm-style .gm-style-iw-c {
        max-width: 420px !important;
        min-width: 200px !important;
    }

}

@media (max-width: 480px) {
    .gm-style .gm-style-iw-c {
        max-width: 260px !important;
        min-width: 200px !important;
    }
}

.container-value-grid {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    flex-wrap: wrap;
    align-content: space-around;
}


.card-container-value-grid {
    background-color: #fff;
    width: 100%;
    padding: 20px;
    margin: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
    /* Ensures padding and border are included in width calculation */
    overflow: hidden;
    /* Prevents overflow that may cause scrolling */
}

/* For tablets and smaller devices (width ≤ 768px) */
@media (max-width: 768px) {
    .card-container-value-grid {
        padding: 15px;
        margin: 10px;
        width: calc(100% - 10px);
        margin-top: 2rem;
        /* Ensures padding is accounted for */
    }
}

/* For mobile devices (width ≤ 480px) */
@media (max-width: 480px) {
    .card-container-value-grid {
        height: 13rem;
        padding: 10px;
        margin: 5px;
        width: calc(100% - 10px);
        margin-top: 2rem;
        /* Ensures padding is accounted for */
    }

    .card-container-value-grid button {
        margin: 1rem auto;
    }
}

/* Container for the cards */
.card-container-logistics-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
    width: 103%;
    padding: 20px 0;
}

.card-container-logistics-internal-grid {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #307E83;
    padding: 20px;
    border-radius: 8px;
    color: white;
    background-size: cover;
    background-position: center;
    min-height: 6rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

/* Card styling */
.card-logistics-grid {

    color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 30%;
    text-align: left;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    margin-top: 10px;
    /* Shows hand cursor */
}

.card-logistics-grid div {
    margin: 0.5rem 0;
}

.card-logistics-grid:hover {
    transform: scale(1.05);
}

/* Card title */
.card-logistics-grid-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Card info text */
.card-logistics-grid-info {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .card-logistics-grid {
        width: 100%;
    }
}

/* Overlay background */
.card-logistics-grid-overlay {
    position: fixed;
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* Adds spacing to prevent content from touching edges */
    z-index: 1000;
}

/* Detail card styling with scroll */
.card-logistics-grid-detail-card {
    background-color: white;
    color: #333;
    padding: 10px;
    border-radius: 10px;
    max-width: 75%;
    max-height: 100%;
    /* Restrict height to 80% of viewport */
    overflow-y: auto;
    /* Enable scrolling if content overflows */
    text-align: start;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
}

.card-logistics-grid-detail-card div {
    margin: 1rem 0;
}

/* Close button styling */
.card-logistics-grid-close-btn {
    background-color: #00796b;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.card-logistics-grid-close-btn:hover {
    background-color: #005f56;
}