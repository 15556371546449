 

.container-legend {
    display: flex;
    flex-direction: column;
}

.facility-legend {
    display: flex;
    align-items: center;
    margin: 10px;
}

.facility-legend img {
    width: 20px;
    height: 30px;
    margin-right: 15px;
    /* Optional: Space between image and text */
}

.facility-legend-name {
    font-size: 15px;
    font-style: normal;
}